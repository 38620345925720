import React, { useState, useEffect } from "react";
import PortfolioDetailsContent from "../elements/portfolio/PortfolioDetailsContent";
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import { getPort } from "../requests/portifolio";

const PortfolioDetails = ({match: {params: {id}}}) => {
    const portfolioId = id
    const [data, setData] = useState(null);

    useEffect(() => {
        async function fetchData() {
            try {
                // You can await here
                const result = await getPort(portfolioId)
                setData(result.data);

            } catch {
                setData(null);
            }
        }
        fetchData();
    }, [portfolioId]);

    return (
        <>
            <SEO title="Portfolio Detalhes || Creatlab" />
            <Layout>
                {data !== null && <PortfolioDetailsContent data={data} />}
            </Layout>
        </>
    )
}

export default PortfolioDetails;
