import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div><img src="https://i.imgur.com/G8EftiG.png" alt="creatlab house" width={100} /><h4 style={{color: "#8c14fc", width: 100}}>{text}</h4></div>;

class GoogleMapStyle extends Component {
  static defaultProps = {
    center: {
      lat: -23.4532837,
      lng: -46.5598263
    },
    zoom: 11
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div className="google-map-style-1">
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAPxWc2iK2Za_DVD-U2wg1VM6sfbtjd4c4' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={-23.4532857}
            lng={-46.5598263}
            text="Creatlab House"
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default GoogleMapStyle;