import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin, FiChevronRight, FiMapPin, FiPhone } from "react-icons/fi";

const HeaderTopBar = () => {
    return (
        <div className="header-top-bar">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-md-12 col-12">
                        <div className="header-left">
                            <p><a rel="noreferrer" href="https://wa.me/5511920002081" target="_blank">Tenha a melhor experiência com nossa agência!<FiChevronRight /></a></p>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-12 col-12">
                        <div className="header-right">
                            <div className="address-content">
                                <p><FiMapPin /><span>São Paulo, Brasil</span></p>
                                <p><FiPhone /><span><a rel="noreferrer" href="https://wa.me/5511920002081" target="_blank">+55 11 92000-2081</a></span></p>
                            </div>
                            <div className="social-icon-wrapper">
                                <ul className="social-icon social-default icon-naked">
                                    <li><a href="https://www.facebook.com/creatlabproducoes"><FiFacebook /></a></li>
                                    <li><a href="twitter.com"><FiTwitter /></a></li>
                                    <li><a rel="noreferrer" href="https://www.instagram.com/creatlab_/" target="_blank"><FiInstagram /></a></li>
                                    <li><a href="linkdin.com"><FiLinkedin /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderTopBar;
