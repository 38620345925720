import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'

// Pages import Here 
import Marketing from "./pages/Marketing";
import AboutUs from "./pages/AboutUs";

// Elements import Here 
import portfolioThreeColumn from "./elements/portfolio/PortfolioThreeColumn";
import PortfolioDetails from "./pages/PortfolioDetails";
import TeamInfo from "./pages/TeamInfo";
import Contact from "./elements/contact/Contact";
import Error from "./pages/Error";
import EntregaPage from "./pages/EntregaPage";

// Import Css Here 
import './assets/scss/style.scss';

const App = () => {
    return (
        <Router>
            <PageScrollTop>
                <Switch>
                    <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={Marketing} />
                    <Route path={`${process.env.PUBLIC_URL + "/about-us"}`} exact component={AboutUs} />
                    <Route path={`${process.env.PUBLIC_URL + "/error"}`} exact component={Error} />
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio"}`} exact component={portfolioThreeColumn} />
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-details/:id"}`} exact component={PortfolioDetails} />
                    <Route path={`${process.env.PUBLIC_URL + "/contact"}`} exact component={Contact} />
                    <Route path={`${process.env.PUBLIC_URL + "/team/info/:id"}`} exact component={TeamInfo} />
                    <Route path={`${process.env.PUBLIC_URL + "/visualizar/:id"}`} exact component={EntregaPage} />
                </Switch>
            </PageScrollTop>
        </Router>
    )
}


export default App
