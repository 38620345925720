import React, { useState, useEffect } from "react";
import axios from 'axios';
import { getVisualiza } from "../requests/visualiza";
import YoutubeEmbed from "../elements/youtube/YoutubeEmbed";
import { FaSpinner } from "react-icons/fa";

import '../assets/scss/style-entrega.css';

const EntregaPage = ({ match: { params: { id } } }) => {
    const visualizaId = id
    const [data, setData] = useState(null);
    const [loadDow, setDown] = useState(0);
    const [downloadLink, setLink] = useState(null);
    const [titleVideo, setTitle] = useState("video")

    useEffect(() => {
        async function fetchData() {
            try {
                // You can await here
                const result = await getVisualiza(visualizaId)
                setData(result.data);

            } catch {
                setData(null);
            }
        }
        fetchData();
    }, [visualizaId]);

    const downloadYT = async (id) => {
        try {
            if (loadDow === 3) return;
            if (loadDow === 2) {
                window.open(`https://creatlab.com.br/down.php?step=3&code=${downloadLink}&title=${titleVideo}`, "_blank")
            }
            if (loadDow === 0) {
                setDown(1)
                let res = await axios('https://creatlab.com.br/down.php?step=1&code=' + id)
                let data = res.data
                setTitle(data.title)
                let consult = await callProgress(data.id)
                setLink(consult)
                setDown(2)
            }
        } catch (error) {
            setDown(3)
        }
    }
    const sleep = ms => new Promise(r => setTimeout(r, ms));

    const callProgress = async (id) => {
        try {
            let res = await axios('https://creatlab.com.br/down.php?step=2&code=' + id)
            let data = res.data
            if (data.text === "Finished") {
                return data.download_url
            } else if (loadDow !== 2) {
                await sleep(5000);
                return await callProgress(id)
            }
        } catch (error) {
            setDown(3)
        }

    }

    const timeConverter = (UNIX_timestamp) => {
        var a = new Date(UNIX_timestamp * 1000);
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour =  "0" +  a.getHours();
        var min = "0" + a.getMinutes();
        var sec = "0" +a.getSeconds();
        var time = date + ' ' + month + ' ' + year + ' ' + hour.substr(-2) + ':' + min.substr(-2) + ':' + sec.substr(-2) ;
        return time;
      }

    if (data == null) return (<> </>)

    return (
        <>
            <div className="background-image" style={{ backgroundImage: `url('https://cms.creatlab.com.br/storage/uploads/${data.backgroundimg.path}')` }}></div>

            <div className="bg-text">
                <div className="video-container">
                    <YoutubeEmbed embedId={data.youtube} />
                </div>
                <div className="descpritons">
                    <h2>{data.cliente}</h2>
                    <p dangerouslySetInnerHTML={{ __html: data.description }} />
                    <button className="btn-default" style={{ width: "100%" }} onClick={() => { downloadYT(data.youtube) }} >
                        {loadDow === 0 && "Download"}
                        {loadDow === 1 && <span className="icon">Carreagando <FaSpinner icon="spinner" className="spinner" /></span>}
                        {loadDow === 2 && "Baixar"}
                        {loadDow === 3 && "Erro ao Baixar"}
                    </button>
                    <p style={{ marginTop: 20 }}>*Qualquer duvida ou pedido de alteração entre em contato com nossa equipe</p>
                    <p>Última atualização: {timeConverter(data._modified)}</p>
                </div>

            </div>

        </>
    )
}

export default EntregaPage;