import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';

const Result = () => {
    return (
        <p className="success-message">Sua mensagem foi enviada com sucesso. Eu entrarei em contato com você em breve.</p>
    )
}

function ContactForm({ props, formStyle }) {
    const [result, showresult] = useState(false);
    const [ativo, setAtivo] = useState(true);

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
            .sendForm(
                'service_1lerulw',
                'template_884duqh',
                e.target,
                'M9mGwxTMXkVtly0ZE'
            )
            .then((result) => {
                console.log(result.text);
            },
                (error) => {
                    console.log(error.text);
                }
            );
        e.target.reset();
        showresult(true);
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);

    function onChange(value) {
        axios.post('https://www.google.com/recaptcha/api/siteverify', {
            secret: value
        }).then(function (response) {
            setAtivo(!response.success)
        }).catch(function (error) {
            console.log(error);
        });
    }


    return (
        <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
            <div className="form-group">
                <input
                    type="text"
                    name="from_name"
                    placeholder="Seu Nome"
                    required
                />
            </div>

            <div className="form-group">
                <input
                    type="email"
                    name="email"
                    placeholder="Endereço de Email"
                    required
                />
            </div>

            <div className="form-group">
                <input
                    type="text"
                    name="contact_number"
                    placeholder="Número de Telefone"
                    required
                />
            </div>


            <div className="form-group">
                <input
                    type="text"
                    name="subject"
                    placeholder="Assunto"
                    required
                />
            </div>

            <div className="form-group">
                <textarea
                    name="message"
                    placeholder="Sua Mensagem"
                    required
                >
                </textarea>
            </div>

            <ReCAPTCHA
                sitekey="6LdN2dUiAAAAAD_ppVusDWkBSZU-CGl8VMnuyEWt"
                onChange={onChange}
            />
            <div className="form-group">
                <button className="btn-default btn-large" disabled={ativo}>Envie Agora</button>
            </div>
            <div className="form-group">
                {result ? <Result /> : null}
            </div>
        </form>
    )
}
export default ContactForm;
