import React, { useState, useEffect } from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import PortfolioOne from "./PortfolioOne";
import { getAllPort } from "../../requests/portifolio";

const PortfolioThreeColumn = () => {
    const [AllItems, setData] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                // You can await here
                const result = await getAllPort()
                result.data.forEach((e, ind) => {
                    e.id = ind + 1
                })
                setData(result.data);
            } catch {
                setData([]);
            }
        }
        fetchData();
    }, []);


    return (
        <>
            <SEO title="Portfolio || CreatLab" />
            <Layout>
                <BreadcrumbOne
                    title="A maneira mais fácil de <br /> mudar o visual da sua empresa."
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Portfolio"
                />
                <div className="main-content">
                    <div className="rwt-portfolio-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign="text-center"
                                        radiusRounded=""
                                        subtitle="Portfolio"
                                        title="Você pode personalizar tudo!"
                                        description=""
                                    />
                                </div>
                            </div>
                            
                            { AllItems.length !== 0 && <PortfolioOne Column="col-lg-4 col-md-6 mt--30 portfolio" alldata={AllItems} /> }
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default PortfolioThreeColumn;
