import React from 'react';
import SEO from "../common/SEO";
import TeamData from "../data/service/TeamData.json";
import { FaWhatsapp, FaMailBulk } from "react-icons/fa";

import '../assets/scss/style-info.css';

const TeamInfo = ({ match: { params: { id } } }) => {
    const userId = parseInt(id, 10)
    const data = TeamData.filter(users => users.id === userId)[0];
    return (
        <>
            <SEO title="Team Info || Creatlab" />
            <div className="infoTeam">
                <div className="element-animation">
                    {/*card-1*/}
                    <div className="card color-card">
                        <ul>
                            <li><i className="fas fa-arrow-left i-l w" /></li>
                            <li><i className="fas fa-ellipsis-v i-r w" /></li>
                            <li><i className="far fa-heart i-r w" /></li>
                        </ul>
                        <div className="circle">
                            <img src={data.foto} alt="profile-pic" />
                        </div>
                        <h1 className="title">{data.nome}</h1>
                        <p className="job-title">{data.titulo}</p>
                        <div className="desc top">
                            <p>Entre em contato por meio do email: {data.email}</p>
                        </div>
                        <a href={`tel:${data.calnumber}`}>
                            <button className="btn color-a top" >Ligar</button>
                        </a>
                        <hr />
                        <div className="container">
                            <div className="content">
                                <div className="grid-2">
                                    <a href={data.apizap}>
                                        <button className="color-b circule" > <FaWhatsapp /></button>
                                        <p className="followers">WahtsApp</p>
                                    </a>
                                </div>
                                <div className="grid-2">
                                    <a href={`mailto:${data.email}`}>
                                        <button className="color-c circule"><FaMailBulk /></button>
                                        <p className="followers">Email</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default TeamInfo;
